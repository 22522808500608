export default {
  namespaced: true,
  state: {
    elements: [
    ],
    elements_eliminados:[],
    noticias_elementos:[],
    paginas_elementos: [],
    categorias_elementos: [],
    entradas_elementos: [],
    tipoentradas_elementos: [],
    fiscalias_elementos:[],
    convocatorias_elementos:[],
    eventos_elementos:[],
    libros_elementos:[],
    modulos_elementos:[],
    enlacemenus_elementos:[],
  },
  mutations: {
    updateElementsEnlaceMenus: (state,payload)=>{
      state.enlacemenus_elementos=payload
    },

    updateElementsModulos: (state,payload)=>{
      state.modulos_elementos=payload
    },

    updateElementsLibros: (state,payload)=>{
      state.libros_elementos=payload
    },

    updateElementsEventos: (state,payload)=>{
      state.eventos_elementos=payload
    },


    updateElementsConvocatorias: (state,payload)=>{
      state.convocatorias_elementos=payload
    },
    updateElementsFiscalias: (state,payload)=>{
      state.fiscalias_elementos=payload
    },
    updateElementsNoticias: (state,payload)=>{
      state.noticias_elementos=payload
    },
    updateElementsPaginas: (state,payload)=>{
      state.paginas_elementos=payload
    },
    updateElementsCategorias: (state,payload)=>{
      state.categorias_elementos=payload
    },
    updateElementsEntradas: (state,payload)=>{
      state.entradas_elementos=payload
    },
    updateElementsTipoEntradas: (state,payload)=>{
      state.tipoentradas_elementos=payload
    },
    updateElements: (state, payload) => {
      state.elements = payload
    },
    updateElementsEliminados: (state, payload) => {
      state.elements_eliminados = payload
    },
    addElements: (state, payload) => {
      state.elements.push(payload)
    },
    addElementsEliminados:(state,payload)=>{
      state.elements_eliminados.push(payload);
    }
  },
  actions: {
    
    updateElementsEnlaceMenus: ({commit}, payload)=>{
      commit('updateElementsEnlaceMenus', payload)
    },

    updateElementsModulos: ({commit}, payload)=>{
      commit('updateElementsModulos', payload)
    },

    updateElementsLibros: ({commit}, payload)=>{
      commit('updateElementsLibros', payload)
    },

    updateElementsEventos: ({commit}, payload)=>{
      commit('updateElementsEventos', payload)
    },

    updateElementsConvocatorias: ({commit}, payload)=>{
      commit('updateElementsConvocatorias', payload)
    },
    updateElementsNoticias: ({commit}, payload)=>{
      commit('updateElementsNoticias', payload)
    },
    updateElementsFiscalias: ({commit}, payload)=>{
      commit('updateElementsFiscalias', payload)
    },
    updateElementsCategorias: ({commit}, payload)=>{
      commit('updateElementsCategorias', payload)
    },
    updateElementsEntradas: ({commit}, payload)=>{
      commit('updateElementsEntradas', payload)
    },
    updateElementsTipoEntradas: ({commit}, payload)=>{
      commit('updateElementsTipoEntradas', payload)
    },
    updateElementsPaginas: ({commit}, payload)=>{
      commit('updateElementsPaginas', payload)
    },
    updateElements: ({ commit }, payload) => {
      commit('updateElements', payload)
    },
    updateElementsEliminados: ({ commit }, payload) => {
      commit('updateElementsEliminados', payload)
    },
    addElements: ({ commit }, payload) => {
      commit('addElements', payload)
    },
    addElementsEliminados:({ commit },payload)=>{
      commit('addElementsEliminados', payload)
    }
  },
}
