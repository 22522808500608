export default {
    namespaced: true,
    state: {
        elements: [
        ],
        elements_eliminados: [],
        organigrama_elementos: [],

    },
    mutations: {
        updateElementsOrganigrama: (state, payload) => {
            state.organigrama_elementos = payload
        },
        updateElements: (state, payload) => {
            state.elements = payload
        },
        updateElementsEliminados: (state, payload) => {
            state.elements_eliminados = payload
        },
        addElements: (state, payload) => {
            state.elements.push(payload)
        },
    },
    actions: {
        updateElementsOrganigrama: ({ commit }, payload) => {
            commit('updateElementsOrganigrama', payload)
        },
        updateElements: ({ commit }, payload) => {
            commit('updateElements', payload)
        },
        updateElementsEliminados: ({ commit }, payload) => {
            commit('updateElementsEliminados', payload)
        },
        addElements: ({ commit }, payload) => {
            commit('addElements', payload)
        },
    },
}
