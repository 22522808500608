import Vue from 'vue'
import NProgress from 'nprogress'

import axios from 'axios'
// console.log(window.laravel.csrfToken)
const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
})

axiosIns.interceptors.request.use(config => {
  NProgress.start()
  return config
});

// before a response is returned stop nprogress
axiosIns.interceptors.response.use(response => {
  NProgress.done()
  return response
})

Vue.prototype.$http = axiosIns

export default axiosIns
