export default {
  namespaced: true,
  state: {
    usuario: {},
    tokenpage:'',
 
  },
  mutations: {  
    setUsuario: (state, payload) => {
      state.usuario=payload
    },
  },
  actions: {  
    setUsuario: ({ commit }, payload) => {
      commit('setUsuario', payload)
    },
  },
}
