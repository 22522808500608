import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
//CkEditor5
import CKEditor from '@ckeditor/ckeditor5-vue2'

import router from './router'
import store from './store'
import App from './App.vue'
import NProgress from 'nprogress'

import Permissions from './mixins/Permissions';
Vue.mixin(Permissions);

require('@/store/auth/subscriber')

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/clipboard'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '../node_modules/nprogress/nprogress.css'

//Vue Progress
Vue.use(NProgress)

//Registro CKEditor5
Vue.use(CKEditor)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

//ckeditor
// import CKEditor from '@ckeditor/ckeditor5-vue2';

// Vue.use( CKEditor );

//vue validator esp
import { localize } from 'vee-validate';
import es from 'vee-validate/dist/locale/es'
localize('es', es)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})
