//import axios from 'axios'
import axios from "@axios";
//import axiosIns from '@/libs/axios'

export default({
    namespaced:true,
    state:{
        token:null,
        user:null,
    },
    getters:{
        authenticated(state){
           return state.token && state.user
        },
        user(state){
           return state.user 
        },
    },
    mutations:{
       SET_TOKEN(state,token){
        state.token=token
       }, 
       SET_USER(state,data){
        state.user=data
       }, 
    },
    actions:{
        async signIn({dispatch},credentials){
            let response= await axios.post('/auth/signin',credentials)
            return dispatch('attempt',response.data.token)
        },
        async vefiryCode({dispatch,state}, code){
            let response= await axios.post('/auth/verify2FactorCode',code)
            return dispatch('attempt',state.token)
        },
        async attempt({commit,state},token){
            if (token) {
                commit('SET_TOKEN',token)  
            }
            if (!state.token) {
                return
            }
            try {
                let response= await axios.get('/auth/me')
                commit('SET_USER',response.data)
            } catch (e) {
                commit('SET_TOKEN',null)
                commit('SET_USER',null)
            }
        },
        signOut({commit}){
            return axios.post('/auth/signout').then(()=>{
                commit('SET_TOKEN',null)
                commit('SET_USER',null)
            })
        }
    },
})