import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import aparienciaMenus from './apariencia-menus'

import auth from "./auth"
import enlaces from './enlaces'
import organigrama from './organigrama'
import fusuario from './fusuario'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    aparienciaMenus,
    auth,
    enlaces,
    organigrama,
    fusuario,
  },
  strict: process.env.DEV,
})
