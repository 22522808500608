export default {
  namespaced: true,
  state: {
    elements: [
    ],
    elements_eliminados:[],
    enlaces_elementos: [],
 
  },
  mutations: {
    updateElementsEnlaces: (state,payload)=>{
      state.enlaces_elementos=payload
    },
    updateElements: (state, payload) => {
      state.elements = payload
    },
    updateElementsEliminados: (state, payload) => {
      state.elements_eliminados = payload
    },
    addElements: (state, payload) => {
      state.elements.push(payload)
    },
  },
  actions: {
    updateElementsEnlaces: ({commit}, payload)=>{
      commit('updateElementsEnlaces', payload)
    },
    updateElements: ({ commit }, payload) => {
      commit('updateElements', payload)
    },
    updateElementsEliminados: ({ commit }, payload) => {
      commit('updateElementsEliminados', payload)
    },
    addElements: ({ commit }, payload) => {
      commit('addElements', payload)
    },
  },
}
