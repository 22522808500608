<script>
import store from '@/store'
  export default {
    data() {
        return{
            mixing:store.getters['auth/authenticated'],
        }
    },
    methods: {
      $hascan(name) {
        return this.mixing.permisos.indexOf(name) !== -1;
      },
    },
  };
</script>